import React from 'react';
import Helmet from 'react-helmet';

import {
    Default
} from '../../components';

import {
    Container,
    Heading,
    Paragraph,
    InputGroup,
    Field,
    Label,
    Button,
    Textarea
} from '../../components/PageStyles/ContactStyles';

const Contact = ({ location }) => {
    return (
        <Default
            location={location}
        >
            <Helmet>
                <title>XPERRIA</title>
                <meta name="description" content="Get in contact with XPERRIA"/>
            </Helmet>
            <Container>
                <Heading>
                Hubungi Kami
                </Heading>
                <Paragraph>
                Apakah itu untuk sebuah proyek atau hanya untuk berhenti dan berkata "Hai!", gunakan formulir ini.
                </Paragraph>
                <form method="POST" action="https://formspree.io/">
                    <InputGroup>
                        <Label htmlFor="name">Nama</Label>
                        <Field name="name" id="name" type="text" required/>
                    </InputGroup>
                    <InputGroup>
                        <Label htmlFor="email">Email</Label>
                        <Field name="email" id="email" type="email" required/>
                    </InputGroup>
                    <InputGroup>
                        <Label htmlFor="message">Pesan</Label>
                        <Textarea name="message" required></Textarea>
                    </InputGroup>
                    <Button type="submit">Let's Go!</Button>
                </form>
            </Container>
        </Default>
    );
}

export default Contact;
